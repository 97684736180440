import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import * as pinsActions from "../../../store/pin";
import PinIndexItem from "./PinIndexItem";
import Masonry from "react-masonry-css";
import "./PinIndex.css";

function PinIndex() {
  const dispatch = useDispatch();
  //   const { username } = useParams();
  const { boardId } = useParams();
  // const pins = useSelector(({ pins }) => {
  //   return Object.values(pins).filter((pin) => {
  //     return pin.boardId === parseInt(boardId);
  //   });
  // });
  // const BoardPinsSelector = (boardId) =>
  //   useSelector(({ pins }) => {
  //     return Object.values(pins).filter((pin) => {
  //       return pin.boardId === parseInt(boardId);
  //     });
  //   });
  // const AllPinsSelector = () => useSelector(({ pins }) => Object.values(pins));
  // const pins = boardId ? BoardPinsSelector(boardId) : AllPinsSelector();
  const pins = useSelector((state) =>
    state.pins ? Object.values(state.pins) : []
  );

  useEffect(() => {
    if (boardId) {
      // previous thunk:
      // dispatch(pinsActions.fetchPins(boardId));
      dispatch(pinsActions.fetchBoardPins(boardId));
    } else {
      dispatch(pinsActions.fetchAllPins());
    }
  }, [boardId]);

  const breakpointColumnsObj = {
    default: 6,
    1500: 4,
    1200: 3,
    1000: 2,
    700: 1,
  };

  const items =
    pins &&
    pins.map(function (pin) {
      return <PinIndexItem key={pin.id} pin={pin} />;
    });

  return (
    <>
      {items.length === 0 ? (
        <div className="no-pins-yet">
          There aren’t any Pins on this board yet
        </div>
      ) : (
        <Masonry
          breakpointCols={breakpointColumnsObj}
          className="masonry-grid"
          columnClassName="masonry-grid_column"
        >
          {items}
        </Masonry>
      )}
    </>
  );
}
export default PinIndex;
